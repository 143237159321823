import axios from "axios";

class AuthService {
    login(email, password) {
        return axios.post(process.env.REACT_APP_URL + ':5000' + process.env.REACT_APP_LOGIN_URL, {
            email,
            password
        })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    return response.data;
                }
            });
    }

    logout () {
        localStorage.removeItem('user');
    }

    register (email, password) {
        return axios.post(process.env.REACT_APP_URL + ':5000' + process.env.REACT_APP_REGISTER_URL, {
            email,
            password
        })
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }
}

export default new AuthService();