import React, {useEffect, useState} from 'react';
import Navigation from "../../components/admin/Navigation";
import {getProjects} from "../../feature/project.slice";
import {Link, NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import {slug} from "../../utils/Tools";
const Project = () => {
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects.projectsData);
    const MySwal = withReactContent(Swal);
    let accessToken = JSON.parse(localStorage.getItem("user"));
    const [message, setMessage] = useState({error: '', message: ''});

    useEffect(() => {
        dispatch(getProjects())
    }, []);

    const handleDelete = (id) => {
        MySwal.fire({
            title: 'Voulez vous supprimé ce projet ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_URL + ':5000/api/project/' + id, {
                    headers: {
                        Authorization: `Bearer ${accessToken.token}`
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        MySwal.fire(
                            'Supprimé !',
                            '',
                            'success'
                        )

                        dispatch(getProjects())
                    }
                }).catch((err) => {
                    setMessage({error: 'error_show', message: err.message});
                });
            }
        })
    }

    return (
        <div>
            <Navigation/>
            <main className="main-content border-radius-lg">
                <div className="container-fluid py-4">
                    <NavLink to="/dashboard" style={{marginRight: '10px'}}>
                        <button className="btn btn-primary" type="button">Retour</button>
                    </NavLink>
                    <NavLink to="/dashboard/project/new" className="btn btn-success" style={{color: 'white'}}>
                        Ajouter
                    </NavLink>
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body px-0 pb-2">
                                    <div className="table-responsive p-0">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                            <tr>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Image</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {projects.length > 0 && projects
                                                .map((project) => (
                                                        <tr key={project._id}>
                                                            <td>
                                                                <div className="d-flex px-2 py-1">
                                                                    <div>
                                                                        <img style={{maxWidth: '100px'}} className="pt-3 pb-3"
                                                                             width="300"
                                                                             src={'../uploads/' + project.files[0].fileName}/>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{project.name}</p>
                                                            </td>
                                                            <td>
                                                                <span style={{cursor:'pointer'}}
                                                                      className="text-secondary font-weight-bold text-xs">
                                                                    <Link to={'/dashboard/project/' + slug(project.name)} state={project}>
                                                                        Voir
                                                                    </Link>
                                                                </span>
                                                                <span className="p-2"></span>
                                                                <span style={{cursor:'pointer'}} onClick={() => handleDelete(project._id)}
                                                                      className="text-secondary font-weight-bold text-xs">
                                                                    Supprimer
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Project;