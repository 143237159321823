import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const getCustomers = createAsyncThunk('getCustomers', async (_, thunkAPI) => {
    axios.get(process.env.REACT_APP_URL + ':5000/api/customer').then((res) => thunkAPI.dispatch(getCustomersSuccess(res.data)));
});

export const customerSlice = createSlice({
    name: 'customers',
    initialState: {
        customersData: []
    },
    reducers: {
        getCustomersSuccess: (state, {payload}) => {
            state.customersData = payload;
        }
    }
});

export const {getCustomersSuccess} = customerSlice.actions;
export default customerSlice.reducer;