import React, {useEffect, useState} from 'react';
import Navigation from "../components/Navigation";
import {useDispatch, useSelector} from "react-redux";
import {getProjects} from "../feature/project.slice";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Autoplay} from "swiper";
import {Link} from "react-router-dom";
import {slug, truncate} from "../utils/Tools";

const Projects = () => {
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects.projectsData);

    useEffect(() => {
        dispatch(getProjects())
    }, []);

    return (
        <>
            <Navigation/>
            <div className="container">
                <h1 className="mb-3 text-center">Projets</h1>
                {/*<div className="row">
                    {projects.length > 0 &&
                        projects.map((project) =>
                            <div className="col-md-6 mb-xl-0 mb-4" key={project._id}>
                                <div className="card card-blog card-plain shadow-lg p-3 mb-5 bg-white rounded">
                                    <div className="card-header p-0 mt-n4 mx-3">
                                        <Swiper
                                            slidesPerView={1}
                                            spaceBetween={10}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 1000,
                                                disableOnInteraction: false,
                                            }}
                                            navigation={false}
                                            modules={[Autoplay]}
                                            className="mySwiper"
                                            breakpoints={{
                                                "@0.00": {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10,
                                                },
                                                "@0.75": {
                                                    slidesPerView: 2,
                                                    spaceBetween: 20,
                                                },
                                                "@1.00": {
                                                    slidesPerView: 3,
                                                    spaceBetween: 40,
                                                },
                                                "@1.50": {
                                                    slidesPerView: 4,
                                                    spaceBetween: 50,
                                                },
                                            }}>
                                            {
                                                project.files.map((res) => <SwiperSlide key={res._id}>
                                                    <img alt={res.name} src={'../../uploads/' + res.fileName}/>
                                                </SwiperSlide>)
                                            }
                                        </Swiper>
                                        <div className="card-body p-3">
                                            <h5>{project.name}</h5>
                                            <p className="mb-4 text-sm"
                                               dangerouslySetInnerHTML={{__html: truncate(project.description, 50)}}></p>
                                            <Link type="button" className="btn btn-primary" to={'/projet/' + slug(project.name)} state={project}>
                                                Voir le projet
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>*/}
                <div className="row">
                    <h2 className="text-center"><span style={{fontSize: '50px', color: 'red'}} className="material-symbols-outlined">engineering</span><br/>En construction</h2>
                </div>
            </div>
        </>
    );
};

export default Projects;