import React from 'react';

const Footer = () => {
    return (
        <footer className="p-4">
            <div className="credits text-center">
                <p>Christopher TRIERWEILER - 2023</p>
            </div>
        </footer>
    );
};

export default Footer;