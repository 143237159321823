import React from 'react';
import Navigation from "../components/admin/Navigation";

const Dashboard = () => {
    return (
        <>
            <Navigation/>
            <div className="dashboard container">

            </div>
        </>
    );
};

export default Dashboard;