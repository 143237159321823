import React, {useRef, useState} from 'react';
import Navigation from "../../components/admin/Navigation";
import {NavLink} from "react-router-dom";
import axios from "axios";

const ExperienceForm = () => {
    const formMess = document.querySelector(".form-message");

    const titleRef = useRef();
    const dateStartRef = useRef();
    const dateEndRef = useRef();
    const missionsRef = useRef();
    const descriptionRef = useRef();
    const techniquessRef = useRef();
    const form = useRef();

    const [missionInput, setMissionInput] = useState([]);
    const [techniqueInput, setTechniqueInput] = useState([]);
    const addInputsMission = () => {
        setMissionInput([...missionInput, {name: `mission-${missionInput.length + 1}`}]);
    };
    const addInputsTechnique = () => {
        setTechniqueInput([...techniqueInput, {name: `technique-${techniqueInput.length + 1}`}]);
    };

    const removeInputMission = (index) => {
        setMissionInput((current) =>
            current.filter((item) => item.name !== index));
    }
    const removeInputTechnique = (index) => {
        setTechniqueInput((current) =>
            current.filter((item) => item.name !== index));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const datas = {
            title: titleRef.current.value,
            description: descriptionRef.current.value,
            dateStart: dateStartRef.current.value,
            dateEnd: dateEndRef.current.value,
            mission: missionInput.map(mission => mission.value),
            technique: techniqueInput.map(technique => technique.value)
        }

        let accessToken = JSON.parse(localStorage.getItem("user"));
        await axios.post(process.env.REACT_APP_URL + ':5000/api/experience', datas, {
            headers: {
                Authorization: `Bearer ${accessToken.token}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    form.current.reset();
                    formMess.innerHTML = "<p className='success_show'>Nouvelle expérience ajouté.</p>";

                    setTimeout(() => {
                        formMess.innerHTML = '';
                    }, 2500);
                }
            })
            .catch((err) => {
                formMess.innerHTML = "<p className='error_show'>Une erreur s'est produite, veuillez réessayer</p>"
            });
    }

    const handleChangeMission = (index, evnt) => {
        const listMission = [...missionInput];
        if (listMission.length > 0) {
            listMission.filter((list) => {
                if (list.name === index) {
                    list.value = evnt.target.value;
                }
            });
        }
        setMissionInput(listMission);
    }
    const handleChangeTechnique = (index, evnt) => {
        const listTechnique = [...techniqueInput];
        if (listTechnique.length > 0) {
            listTechnique.filter((list) => {
                if (list.name === index) {
                    list.value = evnt.target.value;
                }
            });
        }
        setTechniqueInput(listTechnique);
    }

    return (
        <>
            <Navigation/>
            <main className="main-content border-radius-lg">
                <div className="container-fluid py-4">
                    <h1 className="mb-3">Ajout une nouvelle expérience</h1>
                    <div className="form-message"></div>
                    <form ref={form} className="row g-3 needs-validation" noValidate
                          onSubmit={(e) => handleSubmit(e)}>
                        <div className="input-group input-group-outline my-3">
                            <input type="text" className="form-control" id="validationTitle" required
                                   ref={titleRef}/>
                            <label htmlFor="validationTitle" className="form-label">Titre</label>
                        </div>
                        <div className="input-group input-group-outline my-3">
                            <textarea className="form-control" ref={descriptionRef}></textarea>
                            <label htmlFor="validationLevel" className="form-label">Message</label>
                        </div>
                        <div className="input-group input-group-outline my-3">
                            <input type="text" className="form-control" id="validationDateStart" required
                                   ref={dateStartRef}/>
                            <label htmlFor="validationDateStart" className="form-label">Date début</label>
                        </div>
                        <div className="input-group input-group-outline my-3">
                            <input type="text" className="form-control" id="validationDateEnd" required
                                   ref={dateEndRef}/>
                            <label htmlFor="validationDateEnd" className="form-label">Date fin</label>
                        </div>
                        <div className="col-md-12" style={{display: 'inline-grid'}}>
                            <button className="btn btn-primary" onClick={addInputsMission} type="button">
                                <i className="material-symbols-outlined">add</i>
                            </button>
                            <label htmlFor="validationMissions" className="form-label">Missions</label>
                        </div>
                        {missionInput && missionInput.map((item) => {
                            return (
                                <div className="input-group input-group-outline my-3" key={`mission-${item.name}`}>
                                    <input
                                        name="mission"
                                        onChange={(evnt) => handleChangeMission(item.name, evnt)}
                                        id={item.name}
                                        className="form-control"
                                        type={item.type}
                                        size="40"
                                        ref={missionsRef}
                                    />
                                    <button className="btn btn-primary m-0"
                                            onClick={() => removeInputMission(item.name)} type="button">
                                        <i className="material-symbols-outlined">remove</i>
                                    </button>
                                </div>
                            );
                        })}
                        <div className="col-md-12" style={{display: 'inline-grid'}}>
                            <button className="btn btn-primary" onClick={addInputsTechnique} type="button">
                                <i className="material-symbols-outlined">add</i>
                            </button>
                            <label htmlFor="validationMissions" className="form-label">Techniques</label>
                        </div>
                        {techniqueInput && techniqueInput.map((item) => {
                            return (
                                <div className="input-group input-group-outline my-3" key={`technique-${item.name}`}>
                                    <input
                                        name="technique"
                                        onChange={(evnt) => handleChangeTechnique(item.name, evnt)}
                                        id={item.name}
                                        className="form-control"
                                        type={item.type}
                                        size="40"
                                        ref={techniquessRef}
                                    />
                                    <button className="btn btn-primary m-0"
                                            onClick={() => removeInputTechnique(item.name)} type="button">
                                        <i className="material-symbols-outlined">remove</i>
                                    </button>
                                </div>
                            );
                        })}
                        <div className="col-12">
                            <NavLink to="/dashboard" style={{marginRight: '10px'}}>
                                <button className="btn btn-primary" type="button">Retour</button>
                            </NavLink>
                            <button className="btn btn-primary" type="submit">Enregistrer</button>
                        </div>
                    </form>
                </div>
            </main>
        </>
    );
};

export default ExperienceForm;