import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import Contact from "./pages/Contact";
import ExperienceDetail from "./components/ExperienceDetail";
import Dashboard from "./pages/Dashboard";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getUser, isAdmin} from "./feature/user.slice";
import NotFound from "./pages/NotFound";
import SkillForm from "./pages/admin/SkillForm";
import ExperienceForm from "./pages/admin/ExperienceForm";
import Experience from "./pages/admin/Experience";
import Skill from "./pages/admin/Skill";
import Customer from "./pages/admin/Customer";
import CustomerForm from "./pages/admin/CustomerForm";
import Projects from "./pages/Projects";
import Project from "./pages/admin/Project";
import ProjectForm from "./pages/admin/ProjectForm";
import ProjectShow from "./pages/admin/ProjectShow";
import ProjectDetail from "./components/ProjectDetail";

function App() {
    const [userId, setUserId] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('user')) {
            setUserId(localStorage.getItem('user'));
            dispatch(isAdmin(true))
        }
        dispatch(getUser(userId));
    }, [userId]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/projets" element={<Projects/>}/>
                <Route path="/projet/*" element={<ProjectDetail/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/experience/:id" element={<ExperienceDetail/>}/>
                {userId ? <Route path="/dashboard" element={<Dashboard/>}/> : <Route path="/login" element={<Login/>}/>}
                <Route path="/login" element={<Login/>}/>
                {userId && <Route path="/dashboard/skills" element={<Skill/>}/>}
                {userId && <Route path="/dashboard/skill/new" element={<SkillForm/>}/>}
                {userId && <Route path="/dashboard/experiences" element={<Experience/>}/>}
                {userId && <Route path="/dashboard/experience/new" element={<ExperienceForm/>}/>}
                {userId && <Route path="/dashboard/customer/new" element={<CustomerForm/>}/>}
                {userId && <Route path="/dashboard/customers" element={<Customer/>}/>}
                {userId && <Route path="/dashboard/projects" element={<Project/>}/>}
                {userId && <Route path="/dashboard/project/new" element={<ProjectForm/>}/>}
                {userId && <Route path="/dashboard/project/*" element={<ProjectShow/>}/>}
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
