import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCustomers} from "../feature/customer.slice";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Autoplay} from "swiper";

const Customer = () => {
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customers.customersData);

    useEffect(() => {
        dispatch(getCustomers())
    }, []);

    const customerAsc = [...customers].sort((a, b) => a.position < b.position ? -1 : 1);

    return (
        <div className="m-0 p-3">
            <h4 className="text-center text-white">Mes collaborations</h4>

                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    centeredSlides={true}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false,
                    }}
                    navigation={false}
                    modules={[Autoplay]}
                    className="mySwiper"
                    breakpoints={{
                        "@0.00": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        "@0.75": {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        "@1.00": {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        "@1.50": {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        },
                    }}
                >
                    {
                        customerAsc !== undefined ? customerAsc.map((res) => <SwiperSlide key={res._id}>
                            {res.url !== '' ? <a href={res.url} target="_blank"><img alt={res.name} src={'uploads/' + res.fileName} /></a> : <img alt={res.name} src={'uploads/' + res.fileName} />}
                        </SwiperSlide>) : ''
                    }
                </Swiper>
        </div>
    );
};

export default Customer;