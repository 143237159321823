import React, {useRef, useState} from 'react';
import Navigation from "../../components/admin/Navigation";
import axios from "axios";
import {NavLink} from "react-router-dom";

const SkillForm = () => {
    const titleRef = useRef();
    const levelRef = useRef();
    const [formMessage, setFormMessage] = useState({error: '', message: ''});
    const form = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();

        let accessToken = JSON.parse(localStorage.getItem("user"));
        const datas = {
            title: titleRef.current.value,
            level: levelRef.current.value
        }

        await axios.post(process.env.REACT_APP_URL + ':5000/api/skill', datas, {
            headers: {
                Authorization: `Bearer ${accessToken.token}`
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    form.current.reset();
                    setFormMessage({error: 'success_show', message: 'Nouveau langage ajouté'});

                    setTimeout(() => {
                        setFormMessage({error: '', message: ''});
                    }, 2500);
                }
            })
            .catch((err) => {
                setFormMessage({error: 'error_show', message: err.message});
            });
    }

    return (
        <>
            <Navigation/>
            <main className="main-content border-radius-lg">
                <div className="container-fluid py-4">
                    {formMessage ? (
                        <div className={`form-message ${formMessage.error}`}>{formMessage.message}</div>
                    ) : (
                        ''
                    )}
                    <h1>Ajout d'une compétence</h1>
                    <form ref={form} className="row g-3 needs-validation mt-3" noValidate onSubmit={(e) => handleSubmit(e)}>
                        <div className="input-group input-group-outline my-3">
                            <input type="text" className="form-control" id="validationTitle" required
                                   ref={titleRef}/>
                            <label htmlFor="validationTitle" className="form-label">Titre</label>
                        </div>
                        <div className="input-group input-group-outline my-3">
                            <input type="number" min="1" max="5" className="form-control" id="validationLevel"
                                   required
                                   ref={levelRef}/>
                            <label htmlFor="validationLevel" className="form-label">Level</label>
                        </div>
                        <div className="col-12">
                            <NavLink to="/dashboard" style={{marginRight: '10px'}}>
                                <button className="btn btn-primary" type="button">Retour</button>
                            </NavLink>
                            <button className="btn btn-primary" type="submit">Enregistrer</button>
                        </div>
                    </form>
                </div>
            </main>
        </>
    );
};

export default SkillForm;