import {createSlice} from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        userId: null,
        isAdmin: false
    },
    reducers: {
        getUser: (state, {payload}) => {
            state.userId = payload;
        },
        isAdmin: (state, {payload}) => {
            if (payload) state.isAdmin = true;
            else return state.isAdmin = false;
        }
    }
});

export const { getUser, isAdmin } = userSlice.actions;
export default userSlice.reducer;
