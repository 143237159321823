import React from 'react';
import {Link, useLocation} from "react-router-dom";
import Navigation from "./Navigation";
import {Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

const ProjectDetail = () => {
    const projectDetail = useLocation();
    return (
        <>
            <Navigation/>
            <div className="m-4 row">
                <div className="col-md-12">
                    <h4 className="text-center text-white">{projectDetail.state.name}</h4>
                </div>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    centeredSlides={true}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false,
                    }}
                    navigation={false}
                    modules={[Autoplay]}
                    className="mySwiper"
                    breakpoints={{
                        "@0.00": {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        "@0.75": {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        "@1.00": {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        "@1.50": {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        },
                    }}
                >
                    {projectDetail.state.files.map((file) => <SwiperSlide key={file._id}>
                        <img alt={file.name} src={'../../uploads/' + file.fileName}/>
                    </SwiperSlide>)}
                </Swiper>
                <p className="mb-4 text-sm"
                   dangerouslySetInnerHTML={{__html: projectDetail.state.description}}></p>
                <Link className="btn btn-primary w-10" to="/projets">Retour</Link>
            </div>
        </>
    );
};

export default ProjectDetail;