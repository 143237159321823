import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        const formMess = document.querySelector(".form-message");

        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_KEY_API)
            .then((result) => {
                form.current.reset();
                formMess.innerHTML = "<p className='success'>Message envoyé !</p>";

                setTimeout(() => {
                    formMess.innerHTML = '';
                }, 2500);

            }, (error) => {
                formMess.innerHTML = "<p className='error'>Une erreur s'est produite, veuillez réessayer</p>"
            });
    };

    return (
        <>
            <h1 className="mb-3 text-center">Me contacter</h1>
            <form id="contact-form" className="mb-4" ref={form} onSubmit={sendEmail}>
                <div className="row">
                    <div className="mb-3 col-6">
                        <div className="input-group input-group-outline my-3">
                            <input type="text" name="name" required autoComplete="off" id="name"
                                   className="form-control"/>
                            <label className="form-label">Nom</label>
                        </div>
                    </div>
                    <div className="mb-3 col-6">
                        <div className="input-group input-group-outline my-3">
                            <input
                                type="email"
                                name="email"
                                required
                                autoComplete="off"
                                id="email"
                                className="form-control"
                            />
                            <label className="form-label">Email</label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="input-group input-group-outline my-3">
                            <textarea name="message" id="mess" className="form-control"/>
                            <label className="form-label">Message</label>
                        </div>
                    </div>
                    <input type="submit" value="Envoyer" className="btn"/>
                </div>
            </form>
            <div className="form-message"></div>
        </>
    );
};

export default ContactForm;