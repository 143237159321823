import React, {useEffect, useRef, useState} from 'react';
import Navigation from "../../components/admin/Navigation";
import {useDispatch, useSelector} from "react-redux";
import {getCustomers} from "../../feature/customer.slice";
import {NavLink} from "react-router-dom";
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

const Customer = () => {
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customers.customersData);
    const [message, setMessage] = useState({error: '', message: ''});
    let accessToken = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        dispatch(getCustomers())
    }, []);

    const handleEdit = async (id, name, url) => {
        MySwal.fire({
            title: 'Edition du client',
            html: `<input type="text" id="nameEdit" class="swal2-input form-control" value='` + name + `'><br/>
            <input type="text" id="urlEdit" class="swal2-input form-control" value='` + url + `'>`,
            confirmButtonText: 'Enregistrer',
            focusConfirm: false,
            preConfirm: () => {
                const nameVal = Swal.getPopup().querySelector('#nameEdit').value
                const urlVal = Swal.getPopup().querySelector('#urlEdit').value
                if (!nameVal || !urlVal) {
                    Swal.showValidationMessage(`Merci d'entrer un nom.`)
                }
                return {nameVal, urlVal}
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const updateCustomer = {name: result.value.nameVal, url: result.value.urlVal};
                const headers = {
                    Authorization: `Bearer ${accessToken.token}`
                };
                axios.put(process.env.REACT_APP_URL + ':5000/api/customer/' + id, updateCustomer, {headers})
                    .then((res) => {
                        if (res.status === 200) {
                            MySwal.fire(
                                'Modifié !',
                                '',
                                'success'
                            )

                            dispatch(getCustomers())
                        }
                    }).catch((err) => {
                    setMessage({error: 'error_show', message: err.message});
                });
            }
        })
    }
    const handleDelete = (id) => {
        MySwal.fire({
            title: 'Voulez vous supprimé ce client ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(process.env.REACT_APP_URL + ':5000/api/customer/' + id, {
                    headers: {
                        Authorization: `Bearer ${accessToken.token}`
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        MySwal.fire(
                            'Supprimé !',
                            '',
                            'success'
                        )

                        dispatch(getCustomers())
                    }
                }).catch((err) => {
                    setMessage({error: 'error_show', message: err.message});
                });
            }
        })
    }

    // Save position item
    const dragItem = useRef();
    const dragOverItem = useRef();
    const handleSort = () => {
        let _customerItems = [...customers];
        // Rmoeve and save the dragged item content
        const draggedItemContent = _customerItems.splice(dragItem.current, 1)[0];
        // Switch position
        _customerItems.splice(dragOverItem.current, 0, draggedItemContent);
        // Reset position ref
        dragItem.current = null;
        dragOverItem.current = null;
        // Update array
        //customers(_customerItems);
    }

    return (
        <div>
            <Navigation/>
            <main className="main-content border-radius-lg">
                <div className="container-fluid py-4">
                    <NavLink to="/dashboard" style={{marginRight: '10px'}}>
                        <button className="btn btn-primary" type="button">Retour</button>
                    </NavLink>
                    <NavLink to="/dashboard/customer/new" className="btn btn-success" style={{color: 'white'}}>
                        Ajouter
                    </NavLink>
                    <div className="card">
                        <div className="card-body px-0 pb-2">
                            <div className="table-responsive p-0">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Image</th>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nom</th>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {customers.length > 0 && customers
                                        .map((customer) => (
                                                <tr key={customer._id} draggable className="customer_items"
                                                    onDragStart={(e) => dragItem.current = customer.position}
                                                    onDragEnter={(e) => dragOverItem.current = customer.position}
                                                    onDragEnd={handleSort}
                                                    onDragOver={(e) => e.preventDefault()}
                                                >
                                                    <td><span className="material-symbols-outlined">menu</span></td>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div>
                                                                <img style={{maxWidth: '100px'}} className="pt-3 pb-3"
                                                                     width="300"
                                                                     src={'../uploads/' + customer.fileName}/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{customer.name}</p>
                                                    </td>
                                                    <td className="align-middle">
                                                    <span style={{color: 'green', cursor: 'pointer'}}
                                                          onClick={() => handleEdit(customer._id, customer.name !== undefined ? customer.name : '', customer.url !== undefined ? customer.url : '')}
                                                          className="text-secondary font-weight-bold text-xs">
                                                        Modifier
                                                    </span>
                                                        <span className="p-2"></span>
                                                        <span onClick={() => handleDelete(customer._id)}
                                                              style={{color: 'red', cursor: 'pointer'}}
                                                              className="text-secondary font-weight-bold text-xs">
                                                        Supprimer
                                                    </span>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Customer;