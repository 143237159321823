import React, {useState} from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useDispatch} from "react-redux";
import {getSkills} from "../../feature/skill.slice";

const MySwal = withReactContent(Swal)

const Language = ({skill, admin}) => {
    const dispatch = useDispatch();

    const [message, setMessage] = useState({error: '', message: ''});
    const handleDelete = (id) => {
        MySwal.fire({
            title: 'Voulez vous supprimé ce language ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui'
        }).then((result) => {
            if (result.isConfirmed) {
                const datas = {id}
                let accessToken = JSON.parse(localStorage.getItem("user"));

                axios.delete(process.env.REACT_APP_URL + ':5000/api/skill/' + id, {
                    headers: {
                        Authorization: `Bearer ${accessToken.token}`
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        MySwal.fire(
                            'Supprimé !',
                            '',
                            'success'
                        )

                        dispatch(getSkills())
                    }
                }).catch((err) => {
                    setMessage({error: 'error_show', message: err.message});
                });
            }
        })
    }

    return (
        <>
            {message.message ? (
                <div className={`form-message ${message.error}`}>{message.message}</div>
            ) : (
                ''
            )}
            <tr>
                <td>
                    <p className="text-xs font-weight-bold mb-0">{skill.title}</p>
                </td>
                <td>
                    <p className="text-xs font-weight-bold mb-0"><span>{Array(skill.level + 1).join('★')}</span></p>
                </td>
                <td className="text-center">
                    {admin ? <span onClick={() => handleDelete(skill._id)} style={{color: 'red', cursor: 'pointer'}}
                                   className="material-symbols-outlined">close</span> : ''}
                </td>
            </tr>
        </>
    );
};

export default Language;