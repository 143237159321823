import React from 'react';

const Diploma = () => {
    return (
        <div className="m-2">
            <h4 className="text-center text-white">Diplômes/Certifications</h4>
            <ul>
                <li><strong>2014 à 2017</strong> BAC+5 Sécurité Informatique (Mention très bien) école Aston.</li>
                <li><strong>2017</strong> Certification Ethical Hacker.</li>
                <li><strong>2004 à 2008</strong> BEP/BAC PRO ROC SM (Réalisation d'ouvrages chaudronnés structures métalliques) lycée Jean-Pierre Timbault.</li>
            </ul>
        </div>
    );
};

export default Diploma;