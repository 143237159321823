import React, {useEffect, useState} from 'react';
import Navigation from "../../components/admin/Navigation";
import {useDispatch, useSelector} from "react-redux";
import {getExperiences} from "../../feature/experience.slice";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {getSkills} from "../../feature/skill.slice";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

const Experience = () => {
    const dispatch = useDispatch();
    const experiences = useSelector((state) => state.experiences.experiencesData);
    const [message, setMessage] = useState({error: '', message: ''});

    useEffect(() => {
        dispatch(getExperiences())
    }, []);
    const handleDelete = (id) => {
        MySwal.fire({
            title: 'Voulez vous supprimé cette expérience ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui'
        }).then((result) => {
            if (result.isConfirmed) {
                const datas = {id}
                let accessToken = JSON.parse(localStorage.getItem("user"));

                axios.delete(process.env.REACT_APP_URL + ':5000/api/skill/' + id, {
                    headers: {
                        Authorization: `Bearer ${accessToken.token}`
                    }
                }).then((res) => {
                    if (res.status === 200) {
                        MySwal.fire(
                            'Supprimé !',
                            '',
                            'success'
                        )

                        dispatch(getSkills())
                    }
                }).catch((err) => {
                    setMessage({error: 'error_show', message: err.message});
                });
            }
        })
    }

    return (
        <div>
            <Navigation/>
            <main className="main-content border-radius-lg">
                <div className="container-fluid py-4">
                    <NavLink to="/dashboard" style={{marginRight: '10px'}}>
                        <button className="btn btn-primary" type="button">Retour</button>
                    </NavLink>
                    <NavLink to="/dashboard/experience/new" className="btn btn-success" style={{color: 'white'}}>
                        Ajouter
                    </NavLink>
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body px-0 pb-2">
                                    <div className="table-responsive">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                            <tr>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Experience</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Date
                                                    début
                                                </th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date
                                                    fin
                                                </th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                experiences &&
                                                experiences.map((res) => <tr key={res._id}>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{res.title}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="text-xs font-weight-bold">{res.dateStart}</span>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <span className="text-xs font-weight-bold">{res.dateEnd}</span>
                                                    </td>
                                                    <td className="align-middle">
                                                        <div className="progress-wrapper text-center">
                                                            <span onClick={() => handleDelete(res._id)}
                                                                  style={{color: 'red', cursor: 'pointer'}}
                                                                  className="material-symbols-outlined">close</span>
                                                        </div>
                                                    </td>
                                                </tr>)
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Experience;