import React, {useRef, useState} from 'react';
import Navigation from "../../components/admin/Navigation";
import {NavLink} from "react-router-dom";
import axios from "axios";

const CustomerForm = ({customer}) => {
    const formMess = document.querySelector(".form-message");
    const form = useRef();
    const nameRef = useRef();
    const urlRef = useRef();
    const [file, setFile] = useState(null);

    const handleFileSelect = (e) => {
        setFile(e.target.files[0]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const datas = {
            file,
            name: nameRef.current.value,
            url: urlRef.current.value
        }

        let accessToken = JSON.parse(localStorage.getItem("user"));
        await axios.post(process.env.REACT_APP_URL + ':5000/api/customer', datas, {
            headers: {
                Authorization: `Bearer ${accessToken.token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                if (res.data.success) {
                    form.current.reset();
                    formMess.innerHTML = "<p className='success_show'>Nouveau client ajouté.</p>";

                    setTimeout(() => {
                        formMess.innerHTML = '';
                    }, 2500);
                } else {
                    form.current.reset();
                    setFile(null);
                    formMess.innerHTML = "<p className='error_show'>" + res.data.message + "</p>";

                    setTimeout(() => {
                        formMess.innerHTML = '';
                    }, 2500);
                }
            })
            .catch((err) => {
                formMess.innerHTML = "<p className='error_show'>Une erreur s'est produite, veuillez réessayer</p>"
            });
    }
    return (
        <>
            <Navigation/>
            <main className="main-content border-radius-lg">
                <div className="container-fluid py-4">
                    <div className="form-message"></div>
                    <h1 className="mb-3">Ajout un nouveau client</h1>
                    <form ref={form} className="row g-3 needs-validation" noValidate onSubmit={(e) => handleSubmit(e)}>
                        <div className="input-group input-group-outline my-3">
                            <input className="form-control" type="text" name="name" id="nameCustomer" ref={nameRef}/>
                            <label htmlFor="nameCustomer" className="form-label">Nom</label>
                        </div>
                        <div className="input-group input-group-outline my-3">
                            <input className="form-control" type="text" name="url" id="urlCustomer" ref={urlRef}/>
                            <label htmlFor="urlCustomer" className="form-label">URL</label>
                        </div>
                        <div className="input-group input-group-outline my-3">
                            <input className="form-control" type="file" name="file" id="imageCustomer"
                                   onChange={handleFileSelect}/>
                        </div>
                        <div className="col-12 mt-3">
                            <NavLink to="/dashboard" style={{marginRight: '10px'}}>
                                <button className="btn btn-primary" type="button">Retour</button>
                            </NavLink>
                            {file && (
                                <button className="btn btn-primary" type="submit">Enregistrer</button>
                            )}
                        </div>
                    </form>
                </div>
            </main>
        </>
    );
};

export default CustomerForm;