const slug = (title) => {
    return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
}
const truncate = (text, max) => {
    return text?.length > max ? `${text.substring(0, max)}...` : text;
}

module.exports = {
    slug,
    truncate
}