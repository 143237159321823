import React from 'react';

const Volunteer = () => {
    return (
        <div className="m-2">
            <h4 className="text-center text-white">Bénévolat</h4>
            <ul>
                <li><strong>Conseiller municipal</strong> dans la ville de Boutigny-Sur-Essonne.</li>
                <li><strong>Conseiller communautaire</strong> dans la communauté de commune des Deux vallées.</li>
            </ul>
        </div>
    );
};

export default Volunteer;