import React from 'react';
import Navigation from "../../components/admin/Navigation";
import {NavLink, useLocation} from "react-router-dom";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Autoplay} from "swiper";
const ProjectShow = () => {
    const location = useLocation();
    const project = location.state;

    return (
        <div>
            <Navigation/>
            <main className="main-content border-radius-lg">
                <div className="container-fluid py-4">
                    <NavLink to="/dashboard/projects" style={{marginRight: '10px'}}>
                        <button className="btn btn-primary" type="button">Retour</button>
                    </NavLink>
                    <NavLink to="/dashboard/project/new" className="btn btn-success" style={{color: 'white'}}>
                        Ajouter
                    </NavLink>
                    <div className="card">
                        <div className="card-body p-3">
                            {
                                project &&
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 1000,
                                        disableOnInteraction: false,
                                    }}
                                    navigation={false}
                                    modules={[Autoplay]}
                                    className="mySwiper"
                                    breakpoints={{
                                        "@0.00": {
                                            slidesPerView: 1,
                                            spaceBetween: 10,
                                        },
                                        "@0.75": {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        "@1.00": {
                                            slidesPerView: 3,
                                            spaceBetween: 40,
                                        },
                                        "@1.50": {
                                            slidesPerView: 4,
                                            spaceBetween: 50,
                                        },
                                    }}
                                >
                                    {
                                        project.files.map((res) => <SwiperSlide key={res._id}>
                                            <img alt={res.name} src={'../../uploads/' + res.fileName}/>
                                        </SwiperSlide>)
                                    }
                                </Swiper>
                            }
                            {
                                project.name &&
                                <h1>{project.name}</h1>
                            }
                            {
                                project.description &&
                                <h1 dangerouslySetInnerHTML={{__html: project.description}}></h1>
                            }
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default ProjectShow;