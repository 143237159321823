import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getSkills} from "../feature/skill.slice";
import Language from "./Language";

const Skill = () => {
    const dispatch = useDispatch();
    const skills = useSelector((state) => state.skills.skillsData);

    useEffect(() => {
        dispatch(getSkills())
    }, []);

    return (
        <div className="m-2 row">
            <h4 className="text-center text-white">Compétences/Qualifications</h4>
            <ul className="skills_list col-xs-12 col-md-6">
                {skills && skills.slice(0, 7).map((res) => <Language skill={res} key={res._id}/>)}
            </ul>
            <ul className="skills_list col-xs-12 col-md-6">
                {skills && skills.slice(7, 30).map((res) => <Language skill={res} key={res._id}/>)}
            </ul>
        </div>
    );
};

export default Skill;