import {NavLink} from "react-router-dom";
import Pdf from '../documents/CV.pdf'
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import AuthService from "../services/auth.service";

function Navigation() {
    const [showLinks, setShowLinks] = useState(false);
    const user = useSelector((state) => state.user.userId);
    const stickyHeader = useRef()

    useLayoutEffect(() => {
        const navHeader = document.getElementById('navigationHeader');
        let fixedTop = stickyHeader.current.offsetTop
        // Header scrolling
        const fixedHeader = () => {
            if (window.pageYOffset > fixedTop) {
                navHeader.classList.add('fixedTop')
            } else {
                navHeader.classList.remove('fixedTop')
            }
        }
        window.addEventListener('scroll', fixedHeader)
    }, []);

    const handleShowLinks = () => {
        setShowLinks(!showLinks);
    }
    const handleLogout = () => {
        AuthService.logout();
        window.location.replace(process.env.REACT_APP_URL);
    }

    return (
        <nav id="navigationHeader" className={`navigation ${showLinks ? "show-nav" : "hide-nav"}`} ref={stickyHeader}>
            <div className="navbar__logo" style={{paddingLeft: '20px'}}>
                <NavLink to="/">
                    <strong>Christopher TRIERWEILER</strong>
                </NavLink>
            </div>
            <ul className="navbar__links">
                <NavLink to="/"
                         className={(nav) => (nav.isActive) ? "nav-active hover navbar__link slideInDown-1" : "hover navbar__link slideInDown-1"}>
                    <li>Accueil</li>
                </NavLink>
                <NavLink to="/projets"
                         className={(nav) => (nav.isActive) ? "nav-active hover navbar__link slideInDown-1" : "hover navbar__link slideInDown-1"}>
                    <li>Projets</li>
                </NavLink>
                <NavLink to="/contact"
                         className={(nav) => (nav.isActive) ? "nav-active hover navbar__link slideInDown-1" : "hover navbar__link slideInDown-1"}>
                    <li>Contact</li>
                </NavLink>
                <a className="navbar__link slideInDown-1" href={Pdf} target="_blank" rel="noreferrer">
                    <li>Télécharger mon CV</li>
                </a>
                {user &&
                    <NavLink to="/dashboard"
                             className={(nav) => (nav.isActive) ? "nav-active hover navbar__link slideInDown-1" : "hover navbar__link slideInDown-1"}>
                        <li>Dashboard</li>
                    </NavLink>
                }
                {user ? (
                    <button style={{background: 'none'}} onClick={() => handleLogout()} className="navbar__link slideInDown-1" target="_blank"
                            rel="noreferrer">
                        <li><i className="fa-solid fa-right-to-bracket"></i></li>
                    </button>
                ) : (
                    <NavLink to="/login"
                             className={(nav) => (nav.isActive) ? "nav-active hover navbar__link slideInDown-1" : "hover navbar__link slideInDown-1"}>
                        <li><i className="fa-regular fa-user"></i></li>
                    </NavLink>
                )}
            </ul>
            <button className="navbar__burger" onClick={handleShowLinks}>
                <span className="burger-bar"></span>
            </button>
        </nav>
    )
        ;
}

export default Navigation;