import React, {useEffect} from 'react';
import Navigation from "../../components/admin/Navigation";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getSkills} from "../../feature/skill.slice";
import Language from "../../components/admin/Language";

const Skill = () => {
    const dispatch = useDispatch();
    const skills = useSelector((state) => state.skills.skillsData);

    useEffect(() => {
        dispatch(getSkills())
    }, []);

    return (
        <div>
            <Navigation/>
            <main className="main-content border-radius-lg">
                <div className="container-fluid py-4">
                    <NavLink to="/dashboard" style={{marginRight: '10px'}}>
                        <button className="btn btn-primary" type="button">Retour</button>
                    </NavLink>
                    <NavLink to="/dashboard/skill/new" className="btn btn-success" style={{color: 'white'}}>
                        Ajouter
                    </NavLink>
                    <div className="card">
                        <div className="card-body px-0 pb-2">
                            <div className="table-responsive p-0">
                                <table className="table align-items-center mb-0">
                                    <thead>
                                    <tr>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Langage</th>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Compétence</th>
                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {skills && skills.map((res) => <Language admin={true} skill={res} key={res._id}/>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Skill;