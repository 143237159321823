import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getExperiences} from "../feature/experience.slice";
import {Link} from "react-router-dom";
import {slug} from "../utils/Tools";

const Experience = () => {
    const dispatch = useDispatch();
    const experiences = useSelector((state) => state.experiences.experiencesData);

    useEffect(() => {
        dispatch(getExperiences())
    }, []);

    return (
        <div className="m-2">
            <h4 className="text-center text-white">Expériences</h4>
            <ul>
                {
                    experiences &&
                    experiences.map((res) => <li key={res._id}><strong>{res.dateStart} - {res.dateEnd}</strong> {res.title} <Link style={{textDecoration: 'underline'}} state={{id: res._id, mission: res.mission, technique: res.technique, title: res.title}} to={/experience/ + slug(res.title)}>En savoir plus</Link></li>)
                }
            </ul>
        </div>
    );
};

export default Experience;