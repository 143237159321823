import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const getProjects = createAsyncThunk('getProjects', async (_, thunkAPI) => {
    axios.get(process.env.REACT_APP_URL + ':5000/api/project').then((res) => thunkAPI.dispatch(getProjectsSuccess(res.data)));
});

export const projectSlice = createSlice({
    name: 'projects',
    initialState: {
        projectsData: []
    },
    reducers: {
        getProjectsSuccess: (state, {payload}) => {
            state.projectsData = payload;
        }
    }
});

export const {getProjectsSuccess} = projectSlice.actions;
export default projectSlice.reducer;