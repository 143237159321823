import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const getSkills = createAsyncThunk('getSkills', async (_, thunkAPI) => {
    axios.get(process.env.REACT_APP_URL + ':5000/api/skill').then((res) => thunkAPI.dispatch(getSkillsSuccess(res.data)));
});

export const skillSlice = createSlice({
    name: 'skills',
    initialState: {
        skillsData: []
    },
    reducers: {
        getSkillsSuccess: (state, {payload}) => {
            state.skillsData = payload;
        },
        addSkill: (state, {payload}) => {
            state.skillsData.push(payload);
        },
        deleteSkill: (state, {payload}) => {
            console.log(payload);
            state.skillsData.filter((skill) => skill.id !== payload.id)
        }
    }
});

export const {getSkillsSuccess, addSkill, deleteSkill} = skillSlice.actions;
export default skillSlice.reducer;