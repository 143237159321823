import {configureStore} from "@reduxjs/toolkit";
import skillReducer from '../feature/skill.slice';
import experienceReducer from '../feature/experience.slice';
import userReducer from '../feature/user.slice';
import customerReducer from '../feature/customer.slice';
import projectReducer from '../feature/project.slice';
export default configureStore({
    reducer: {
        skills: skillReducer,
        experiences: experienceReducer,
        user: userReducer,
        customers: customerReducer,
        projects: projectReducer
    },
    devTools: process.env.NODE_ENV !== 'production'
});