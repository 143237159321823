import React, {useState} from 'react';
import Navigation from "./Navigation";
import Skill from "./Skill";
import Experience from "./Experience";
import Diploma from "./Diploma";
import Volunteer from "./Volunteer";
import Footer from "./Footer";
import Customer from "./Customer";

const Home = () => {
    const [openToWork, setOpenToWork] = useState(process.env.REACT_APP_OPENTOWORK);
    const [linkedin, setLinkedin] = useState(process.env.REACT_APP_LINKEDIN);
    const getAge = () => {
        let bday = new Date('1988-11-27');
        let today = new Date();
        let distance = today.getTime() - bday.getTime();
        let daysOld = Math.floor(distance / (1000 * 60 * 60 * 24));
        let yearsOld = Number((daysOld / 365).toFixed(0));

        return yearsOld;
    }

    return (
        <>
            <Navigation/>
            <div className="row" id="banner">
                <img style={{zIndex: '1'}} src="./assets/img/banner.png" alt="Christopher TRIERWEILER développeur web"/>
                <h4 style={{zIndex: '1'}} className="hide text-center mb-4 mt-4 text-white">Développeur web | Lead développeur</h4>
            </div>
            <div className="text-center mt-2">
                {openToWork ? <a target="_blank" href={linkedin} className="opentowork">#OPENTOWORK</a> : ''}
                <img alt="CHRISCOM" src="./assets/img/logo.png"/><br />
                <a className="btn btn-primary" rel="noreferrer" href='https://www.chris-com.fr' target="_blank">
                    Voir mon site professionnel
                </a>
            </div>
            <div className="container border-bloc">
                <div className="row">
                    <div className="col-xs-12 p-5">
                        Bonjour! Je suis Christopher Trierweiler j'ai {getAge()} ans, passionné d'informatique et de développement web depuis l'âge de 13 ans. J'ai acquis une solide formation universitaire, avec un BAC+5 en sécurité informatique, ainsi qu'une certification d'ethical hacker délivrée par EC Council.
                        <br />
                        <br />
                        Je suis un fervent autodidacte, toujours en quête de nouvelles connaissances et de compétences pour améliorer mes performances en tant que développeur web. J'adore travailler sur des projets créatifs et stimulants, et je suis constamment à la recherche de nouveaux défis à relever dans ce domaine passionnant.
                        <br />
                        <br />
                        Avec mon expertise approfondie du développement web, je suis en mesure de fournir des solutions efficaces et innovantes pour répondre aux besoins de mes clients. Je suis très motivé et engagé dans mon travail, et j'ai hâte de mettre mes compétences au service de futur projets.
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-6 border-right pt-3">
                        <Diploma/>
                    </div>
                    <div className="col-xs-12 col-md-6 pt-3">
                        <Experience/>
                    </div>
                    <div className="col-xs-12 col-md-6 border-right">
                        <Skill/>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <Volunteer/>
                        <div className="m-2">
                            <h4 className="text-center text-white">Divers</h4>
                            <div className="col-xs-12 d-flex align-items-center justify-content-center">
                                Travailleur handicapé (RQTH)
                                <img src="./assets/img/handicap.jpg" width="100" alt="Travailleur handicapé"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Customer />
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Home;