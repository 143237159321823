import React from 'react';
import Navigation from "../components/Navigation";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import ContactForm from "../components/ContactForm";

const Contact = () => {
    return (
        <>
            <Navigation/>
            <div className="container mt-3">
                <ContactForm/>
                <div className="row text-center">
                    <div className="phone col-6">
                        <div className="content">
                            <h4 className="text-white">téléphone</h4>
                            <CopyToClipboard text="0689810982">
                                <p style={{cursor: "pointer"}} className="clipboard"
                                   onClick={() => alert("Téléphone copié !")}>06 89 81 09 82</p>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="email col-6">
                        <div className="content">
                            <h4 className="text-white">email</h4>
                            <CopyToClipboard text="c.trierweiler@chris-com.fr">
                                <p style={{cursor: "pointer"}} className="clipboard"
                                   onClick={() => alert("Email copié !")}>c.trierweiler@chris-com.fr</p>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;