import React from 'react';
import {Link, useLocation} from "react-router-dom";
import Navigation from "./Navigation";

const ExperienceDetail = () => {
    const experienceDetail = useLocation();
    return (
        <>
            <Navigation/>
            <div className="m-4 row">
                <h3 className="text-center mb-4">{experienceDetail.state.title}</h3>
                <div className="col-md-6">
                    <h4 className="text-center text-white">Missions</h4>
                    <ul className="p-0">
                        {experienceDetail.state.mission && experienceDetail.state.mission.map((mission) =>
                            <li>{mission}</li>)}
                    </ul>
                </div>
                <div className="col-md-6">
                    <h4 className="text-center text-white">Techniques</h4>
                    <ul className="p-0">
                        {experienceDetail.state.technique && experienceDetail.state.technique.map((technique) =>
                            <li>{technique}</li>)}
                    </ul>
                </div>
                <Link className="btn btn-primary w-10" to="..">Retour</Link>
            </div>
        </>
    );
};

export default ExperienceDetail;