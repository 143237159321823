import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";

export const getExperiences = createAsyncThunk('getExperiences', async (_, thunkAPI) => {
    axios.get(process.env.REACT_APP_URL + ':5000/api/experience').then((res) => thunkAPI.dispatch(getExperiencesSuccess(res.data)));
});

export const experienceSlice = createSlice({
    name: 'experiences',
    initialState: {
        experiencesData: []
    },
    reducers: {
        getExperiencesSuccess: (state, {payload}) => {
            state.experiencesData = payload;
        }
    }
});

export const {getExperiencesSuccess} = experienceSlice.actions;
export default experienceSlice.reducer;