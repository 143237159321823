import React, {useRef, useEffect, useState} from 'react';
import Navigation from "./Navigation";
import AuthService from "../services/auth.service";

const Login = () => {
    const email = useRef();
    const password = useRef();
    const user = AuthService.getCurrentUser();
    const [formMessage, setFormMessage] = useState({error: '', message: ''});

    const handleSubmit = (e) => {
        e.preventDefault();

        AuthService.login(email.current.value, password.current.value)
            .then((res) => {
                if (res.token) {
                    window.location.replace(process.env.REACT_APP_PORT ? process.env.REACT_APP_URL + process.env.REACT_APP_PORT + '/dashboard' : '/dashboard');
                }
            })
            .catch((err) => {
                setFormMessage({error: 'error_show', message: err.message});

                setTimeout(() => {
                    setFormMessage({error: '', message: ''});
                }, 2500);
            });
    }

    useEffect(() => {
        if (user) {
            window.location.replace(process.env.REACT_APP_PORT ? process.env.REACT_APP_URL + process.env.REACT_APP_PORT + '/dashboard' : '/dashboard');
        }
    }, [user]);

    return (
        <>
            <Navigation/>
            <div className="container mt-3">
                <h1 className="text-center">Connexion</h1>
                <form className="row g-3 needs-validation" noValidate onSubmit={(e) => handleSubmit(e)}>
                    {formMessage ? (
                        <div className={`form-message ${formMessage.error}`}>{formMessage.message}</div>
                    ) : (
                        ''
                    )}
                    <div className="col-md-12">
                        <div className="input-group input-group-outline my-3">
                            <input type="text" className="form-control" id="validationCustom01" required ref={email}/>
                            <label htmlFor="validationCustom01" className="form-label">Email</label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="input-group input-group-outline my-3">
                            <input type="password" className="form-control" id="validationCustom02" required
                                   ref={password}/>
                            <label htmlFor="validationCustom02" className="form-label">Mot de passe</label>
                        </div>
                    </div>
                    <div className="col-12">
                        <button className="btn btn-primary" type="submit">Connexion</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Login;