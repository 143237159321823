import React, {useRef, useState} from 'react';
import Navigation from "../../components/admin/Navigation";
import {NavLink} from "react-router-dom";
import axios from "axios";
import { Editor } from '@tinymce/tinymce-react';

const ProjectForm = () => {
    const form = useRef();
    const fileRef = useRef();
    const nameRef = useRef();
    const [fileInput, setFileInput] = useState([]);
    const formMess = document.querySelector(".form-message");
    const inputFiles = document.querySelectorAll('.input_files');
    const buttonSubmit = document.querySelector(".submit_button");
    const descriptionRef = useRef();
    const addInputFile = () => {
        setFileInput([...fileInput, {name: `file-${fileInput.length + 1}`}]);
    };
    const removeInputFile = (index) => {
        setFileInput((current) =>
            current.filter((item) => item.name !== index));
    }
    const handleChangeInput = (index, evnt) => {
        const listFile = [...fileInput];
        if (listFile.length > 0) {
            listFile.filter((list) => {
                if (list.name === index) {
                    list.value = evnt.target.files[0];
                    list.url = URL.createObjectURL(evnt.target.files[0]);
                }
            });
        }
        setFileInput(listFile);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        let accessToken = JSON.parse(localStorage.getItem("user"));
        const datas = {
            fileInput,
            name: nameRef.current.value,
            description: descriptionRef.current.getContent()
        }
        await axios.post(process.env.REACT_APP_URL + ':5000/api/project', datas, {
            headers: {
                Authorization: `Bearer ${accessToken.token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                if (res.data.success) {
                    form.current.reset();
                    formMess.innerHTML = "<p className='success_show'>Nouveau projet ajouté.</p>";

                    setTimeout(() => {
                        formMess.innerHTML = '';
                    }, 2500);

                    inputFiles.forEach(inputFile => {
                        inputFile.remove();
                    });
                    buttonSubmit.remove();
                } else {
                    form.current.reset();
                    setFileInput(null);
                    formMess.innerHTML = "<p className='error_show'>" + res.data.message + "</p>";

                    setTimeout(() => {
                        formMess.innerHTML = '';
                    }, 2500);
                }
            });
    };
    return (
        <>
            <Navigation/>
            <main className="main-content border-radius-lg">
                <div className="container-fluid py-4">
                    <h1 className="mb-3">Ajout un nouveau projet</h1>
                    <div className="form-message"></div>
                    <form ref={form} className="row g-3 needs-validation" noValidate onSubmit={(e) => handleSubmit(e)}>
                        <div className="input-group input-group-outline my-3">
                            <input className="form-control" type="text" name="name" id="nameProject" ref={nameRef}/>
                            <label htmlFor="nameProject" className="form-label">Nom</label>
                        </div>
                        <div className="input-group input-group-outline my-3">
                            <Editor
                                apiKey="6qqspl4ft0hyrs8xtx8p91d3an2h4s8x2fvc7vn96jn5pse1"
                                onInit={(evt, editor) => descriptionRef.current = editor}
                                init={{
                                    height: 500,
                                    width: '100%'
                                }}
                            />
                            <label htmlFor="descriptionProject" className="form-label">Description</label>
                        </div>
                        <div style={{display: 'inline-grid'}}>
                            <button className="btn btn-primary" onClick={addInputFile} type="button">
                                <i className="material-symbols-outlined">add</i>
                            </button>
                            <label htmlFor="filesProject" className="form-label">Fichiers</label>
                        </div>
                        {fileInput && fileInput.map((item) => {
                            return (
                                <div key={`file-${item.name}`} className="input_files">
                                    <div className="input-group input-group-outline my-3">
                                        <input
                                            name="fileProject"
                                            multiple
                                            onChange={(evnt) => handleChangeInput(item.name, evnt)}
                                            id={item.name}
                                            className="form-control"
                                            type="file"
                                            size="40"
                                            ref={fileRef}
                                        />
                                        <button className="btn btn-primary m-0"
                                                onClick={() => removeInputFile(item.name)} type="button">
                                            <i className="material-symbols-outlined">remove</i>
                                        </button>
                                    </div>
                                    <div>
                                        <img style={{display: 'block'}} width="150" src={item.url} alt=""/>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="col-12 mt-3">
                            <NavLink to="/dashboard/projects" style={{marginRight: '10px'}}>
                                <button className="btn btn-primary" type="button">Retour</button>
                            </NavLink>
                            {fileInput.length > 0 && (
                                <button className="btn btn-primary submit_button" type="submit">Enregistrer</button>
                            )}
                        </div>
                    </form>
                </div>
            </main>
        </>
    );
};

export default ProjectForm;