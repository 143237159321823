import React from 'react';
import {Link, NavLink} from "react-router-dom";
import AuthService from "../../services/auth.service";
const handleLogout = () => {
    AuthService.logout();
    window.location.replace('/');
}
const Navigation = () => {
    return (
        <>
            <aside
                className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
                id="sidenav-main">
                <NavLink className="navbar-brand m-0 text-white" to="/">
                        <span
                            className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                            <i className="material-icons opacity-10">keyboard_backspace</i>
                            <span className="nav-link-text ms-1">Retour au site</span>
                        </span>
                </NavLink>
                <hr className="horizontal light mt-0 mb-2"/>
                <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link text-white" to="/dashboard">
                                <div
                                    className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">dashboard</i>
                                </div>
                                <span className="nav-link-text ms-1">Dashboard</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/dashboard/skills">
                                <div
                                    className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">code</i>
                                </div>
                                <span className="nav-link-text ms-1">Langages</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/dashboard/projects">
                                <div
                                    className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">dashboard</i>
                                </div>
                                <span className="nav-link-text ms-1">Projets</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/dashboard/experiences">
                                <div
                                    className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">science</i>
                                </div>
                                <span className="nav-link-text ms-1">Expériences</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link text-white" to="/dashboard/customers">
                                <div
                                    className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">person</i>
                                </div>
                                <span className="nav-link-text ms-1">Clients</span>
                            </NavLink>
                        </li>
                        <hr className="horizontal light mt-0 mb-2"/>
                        <li className="nav-item">
                            <NavLink onClick={() => handleLogout()} className="nav-link text-white" to="/">
                                <div
                                    className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                                    <i className="material-icons opacity-10">logout</i>
                                </div>
                                <span className="nav-link-text ms-1">Déconnexion</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </aside>
        </>
    );
};

export default Navigation;