import React from 'react';
import {NavLink} from "react-router-dom";
import Navigation from "../components/Navigation";

const NotFound = () => {
    return (
        <>
        <Navigation />
        <div className="notFound">
            <div className="notFound-content">
                <h2>Erreur 404</h2>
                <p>Page introuvable ...</p>
                <NavLink to="/">
                    <h3>Retour à l'accueil <i className="fa fa-home"></i></h3>
                </NavLink>
            </div>
        </div>
        </>
    );
};

export default NotFound;